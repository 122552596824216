import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import cognito from 'common/cognito'
import { getApi } from 'common/api'
import { service } from 'common/service'
import { getStore } from 'common/store'
import { getCompanies } from './useCompany'
import { devSettingsActions, initialDevSettingsState } from './useDevSettings'
import { userDataActions } from './useUserData'
import { initialUserAnalytics, userAnalyticsActions } from './useUserAnalytics'
import flagsmith from 'flagsmith/isomorphic'

export const userService = service
  .enhanceEndpoints({ addTagTypes: ['User'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUser: builder.query<Res['user'], Req['getUser']>({
        providesTags: ['User'],
        queryFn: async (args, api, _extraOptions, baseQuery) => {
          let user = null
          if (getApi().getToken()) {
            return { data: null }
          }
          try {
            await cognito.getSession()
            user = await cognito.getUser()
          } catch (e) {
            return { data: null }
          }
          try {
            const meResponse = await baseQuery({
              method: 'GET',
              url: '/auth/customer/me',
            })
            if (meResponse?.data?.email) {
              user = {
                ...meResponse.data,
                username: meResponse.data.email,
              }
            }
          } catch (e) {}

          if (!user?.companies) {
            await getCompanies(getStore(), {}, { forceRefetch: true })
          }
          return {
            data: user?.username ? user : null,
          }
        },
      }),
      login: builder.mutation<{}, Req['login']>({
        query: (query: Req['login']) => ({
          body: query,
          method: 'POST',
          url: `auth/customer/`,
        }),
      }),

      logout: builder.mutation<{}, Req['logout']>({
        queryFn: async (args, { dispatch, getState }) => {
          const state = getState()
          try {
            await cognito.logout()
          } catch (e) {}
          if (
            // User doesn't exist in the store state, skip resetting store
            !service.endpoints.getUser.select({})(state)?.data
          ) {
            return { data: {} }
          }
          await dispatch(service.util.resetApiState())
          await dispatch(userDataActions.setUserData(null))

          return { data: {} }
        },
      }),
      verifyAccount: builder.mutation<
        Res['verifyAccount'],
        Req['verifyAccount']
      >({
        query: (query: Req['verifyAccount']) => {
          return {
            body: { token: query.token },
            method: 'POST',
            url: `auth/customer/verify`,
          }
        },
        transformResponse: async (res, _, req) => {
          try {
            await logout(getStore(), { skipClearDevSettings: true })
          } catch (e) {}
          await cognito.loginWithToken(req.username, res)
          getStore().dispatch(
            userDataActions.setUserData({
              first_name: res.first_name,
              last_name: res.last_name,
            }),
          )
          await getUser(getStore(), {}, { forceRefetch: true })
          return res
        },
      }),

      // END OF ENDPOINTS
    }),
  })
export const useUser = userService.endpoints.getUser.useQueryState
export const {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useVerifyAccountMutation,
  // END OF EXPORTS
} = userService

export async function logout(
  store: any,
  data: Req['logout'],
  options?: Parameters<typeof userService.endpoints.logout.initiate>[1],
) {
  await store.dispatch(userService.endpoints.logout.initiate(data, options))
}

export async function getUser(
  store: any,
  data: Req['getUser'],
  options?: Parameters<typeof userService.endpoints.getUser.initiate>[1],
) {
  const result = await store
    .dispatch(userService.endpoints.getUser.initiate(data, options))
    .unwrap()
  return result
}

// Call this on resolving login, if the login action returns a 401, treat it as an unconfirmed email
export const tryConfirmEmailRedirect = function (res: any) {
  if (res?.error?.status === 401) {
    cognito.getSession().then((session) => {
      const email = session.getIdToken().payload?.email
      getApi().confirmEmailRedirect(email)
    })
  }
}
