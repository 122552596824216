import { CompanySubscription } from './types/responses'

export function getRestrictedAccessTitle(sub: CompanySubscription) {
  if (!sub?.is_restricted_access) return null
  if (sub.is_cancelled) {
    return 'Subscription cancelled'
  }
  return 'Review your invoices'
}

export function getRestrictedAccessDescription(sub: CompanySubscription) {
  if (!sub?.is_restricted_access) return null
  if (sub.is_cancelled) {
    return 'In order to top up your account, please reactivate your subscription.'
  }
  return 'In order to top up your account, please review your payment method and any unpaid invoices.'
}
