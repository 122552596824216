import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['selectedMails'] | []

const initialState = [] as InitialStateType

export const selectedMailsSlice = createSlice({
  initialState,
  name: 'selectedMails',
  reducers: {
    setSelectedMails(state, action: PayloadAction<Req['setSelectedMails']>) {
      state = action.payload
      return state
    },
  },
})

export const selectedMailsActions = selectedMailsSlice.actions
export const useSelectedMailsActions = () => {
  const dispatch = useDispatch()
  const setSelectedMails = useCallback(
    (payload: Req['setSelectedMails']) => {
      return dispatch(selectedMailsActions.setSelectedMails(payload))
    },
    [dispatch],
  )
  return { setSelectedMails }
}

const selectSelectedMails = (state: StoreStateType) => state.selectedMails

export const useSelectedMails = () => {
  const { setSelectedMails } = useSelectedMailsActions()
  const selectedMails = useSelector(selectSelectedMails)
  return useMemo(
    () => ({ selectedMails, setSelectedMails }),
    [setSelectedMails, selectedMails],
  )
}
