import { Project } from './project'
import { getApi } from './api-common'
import { getStrings } from './strings'
import cognito from './cognito'

export const getQueryString = (params: any): string => {
  const esc = encodeURIComponent
  return Object.keys(params)
    .map((k) => `${esc(k)}=${esc(params[k])}`)
    .join('&')
}

export enum RequestMethod {
  get = 'get',
  put = 'put',
  delete = 'delete',
  patch = 'patch',
  post = 'post',
}

interface RequestOptions {
  timeout: number
  method: RequestMethod
  headers: any
  body?: string
}
const _data = {
  async _request(
    method: RequestMethod,
    url: string,
    data: any,
    headers: any = {},
    proxied?: boolean,
  ): Promise<any> {
    const skipAuthHeader = Object.keys(headers).length > 0
    const controller =
      typeof AbortController !== 'undefined' ? new AbortController() : null
    const signal = controller?.signal

    const options: RequestOptions = {
      headers: {
        'Accept-Language': getStrings().getLanguage(),
        ...headers,
      },
      method,

      // @ts-ignore
      signal,

      timeout: 60000,
    }
    let qs = ''

    if (method !== RequestMethod.get && !options.headers['content-type'])
      options.headers['content-type'] = 'application/json'

    try {
      const session = await cognito.getSession()
      if (session) {
        _data.token = await session?.getAccessToken()?.getJwtToken()
      }
    } catch (e) {}

    if (_data.token && !skipAuthHeader) {
      // add auth tokens to headers of all requests
      options.headers.AUTHORIZATION = `Bearer ${_data.token}`
    }
    if (data) {
      if (method === RequestMethod.get) {
        qs = getQueryString(data)
        url += url.indexOf('?') !== -1 ? `&${qs}` : `?${qs}`
      } else if (options.headers['content-type'] === 'application/json') {
        options.body = JSON.stringify(data)
      } else {
        options.body = data
      }
    } else if (method === RequestMethod.post || method === RequestMethod.put) {
      options.body = '{}'
    }
    const newUrl = Project.api + url

    getApi().log('API', 'REQUEST', method, newUrl, data, headers)

    const req = fetch(newUrl, options)
    let handled = false

    return req
      .then((res) => {
        handled = true
        return _data.status(res)
      })
      .then((response) => {
        // always return json
        let contentType = response.headers.get('content-type')
        if (!contentType) {
          contentType = response.headers.get('Content-Type')
        }
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json()
        }
        return {}
      })
      .then((response) => {
        getApi().log(
          'API',
          'RESPONSE',
          method,
          url,
          'Response body',
          response,
          'Original request',
          options,
        )
        return response
      })
      .catch((e) => {
        throw e
      })
  },
  delete(url: string, data?: any, headers?: any): Promise<any> {
    return _data._request(RequestMethod.delete, url, data, headers)
  },
  get(url: string, data?: any, headers?: any): Promise<any> {
    return _data._request(RequestMethod.get, url, data || null, headers)
  },
  patch(url: string, data: any, headers?: any): Promise<any> {
    return _data._request(RequestMethod.patch, url, data, headers)
  },

  post(url: string, data: any, headers?: any): Promise<any> {
    return _data._request(RequestMethod.post, url, data, headers)
  },

  put(url: string, data: any, headers?: any): Promise<any> {
    return _data._request(RequestMethod.put, url, data, headers)
  },

  refreshToken: '',

  setRefreshToken(_refreshToken?: string): void {
    // set the token for future requests
    _data.refreshToken = _refreshToken || ''
  },

  setToken: (_token?: string): void => {
    // set the token for future requests
    _data.token = _token || ''
  },

  async status(response: any): Promise<any> {
    // handle ajax requests
    // console.debug(response);
    if (response.status === 403) {
      getApi().logout?.()
      return Promise.reject({ message: 'UNAUTHORIZED' })
    }
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response)
    }
    return response
      .clone()
      .text() // cloned so response body can be used downstream
      .then((err: string) => {
        if (
          // @ts-ignore
          typeof E2E !== 'undefined' &&
          // @ts-ignore
          E2E &&
          // @ts-ignore
          document?.getElementById('e2e-error')
        ) {
          const error = {
            error: err,
            status: response.status,
            url: response.url,
          }
          if (document.getElementById('e2e-error')) {
            // @ts-ignore
            document.getElementById('e2e-error').innerText =
              JSON.stringify(error)
          }
        }
        getApi().log(response.url, response.status, err)

        // eslint-disable-next-line
        return Promise.reject({
          ...response,
          _bodyText: err,
          httpStatus: response.status,
        })
      })
  },

  token: '',

  type: '',
}
export default _data
