import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { sdk } from 'common/sdk'

export const groupedOfferService = service
  .enhanceEndpoints({ addTagTypes: ['GroupedOffer'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getGroupedOffers: builder.query<
        Res['groupedOffers'],
        Req['getGroupedOffers']
      >({
        providesTags: [{ id: 'LIST', type: 'GroupedOffer' }],
        queryFn: async () => {
          const tags = await sdk.getTags({ types: ['OFFER'] })
          const offers = await Promise.all(
            tags.map((tag) => sdk.getOffers({ page_size: 5, tags: [tag.id] })),
          )
          return {
            data: {
              groups: tags
                .map((tag, i) => {
                  return {
                    offers: offers[i],
                    tag: tag,
                  }
                })
                .filter((v) => !!v.offers?.results?.length),
              tags,
            } as Res['groupedOffers'],
          }
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getGroupedOffers(
  store: any,
  data: Req['getGroupedOffers'],
  options?: Parameters<
    typeof groupedOfferService.endpoints.getGroupedOffers.initiate
  >[1],
) {
  store.dispatch(
    groupedOfferService.endpoints.getGroupedOffers.initiate(data, options),
  )
  return Promise.all(
    store.dispatch(groupedOfferService.util.getRunningQueriesThunk()),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetGroupedOffersQuery,
  // END OF EXPORTS
} = groupedOfferService

/* Usage examples:
const { data, isLoading } = useGetGroupedOffersQuery({ id: 2 }, {}) //get hook
const [createGroupedOffers, { isLoading, data, isSuccess }] = useCreateGroupedOffersMutation() //create hook
groupedOfferService.endpoints.getGroupedOffers.select({id: 2})(store.getState()) //access data from any function
*/
