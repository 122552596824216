import React, { FC } from 'react'
import TopupSelect from 'components/TopupSelect'
import PaymentWarning from 'components/project/cards/PaymentWarning'
import SubscriptionSelect from 'components/SubscriptionSelect'
import { usePaymentWarning } from 'common/hooks/usePaymentWarning'
import PreviewBar from 'components/PreviewBar'
import usePreview from 'project/usePreview'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { kycNeedsVerifying } from 'common/services/useKycStatus'
import Icon from 'project/Icon'
import Link from 'next/link'
import { useFlags } from 'flagsmith/react'
import SiteSearch from 'components/SiteSearch'

type ComponentType = {
  extraLinks?: React.ReactNode
}

const Nav: FC<ComponentType> = ({ extraLinks }) => {
  const { paymentWarning, status } = usePaymentWarning()
  const { preview } = usePreview()
  const { subscriptionDetail } = useDefaultSubscription()
  const kyc = subscriptionDetail?.kyc_status
  const needsVerify =
    kycNeedsVerifying(subscriptionDetail) &&
    !subscriptionDetail?.is_partner_customer

  const { feature_kyc, feature_topups } = useFlags([
    'feature_topups',
    'feature_kyc',
  ])
  return (
    <>
      <nav className='navbar navbar-expand-md nav-root'>
        <div
          className='gap-10 mx-4 d-flex w-100 flex-row justify-content-between align-items-center'
          id='navbarCollapse'
        >
          <div className='flex-1 me-4'>
            <SiteSearch />
          </div>
          <div className='d-flex flex-row align-items-center gap-5'>
            {feature_topups.enabled && <TopupSelect />}
            <SubscriptionSelect />
          </div>
        </div>
      </nav>
      <PreviewBar />
      {paymentWarning && !needsVerify && (
        <div className='container-fluid'>
          <div className={'text-white rounded-lg bg-dark py-3 px-4 mb-4'}>
            <PaymentWarning theme='long' />
          </div>
        </div>
      )}

      {!!needsVerify && (
        <div className='container-fluid'>
          <div className={'text-white rounded-lg bg-danger mb-4 py-3 px-4'}>
            <div className='flex-row align-items-center'>
              <div className='flex-fill text-sm'>
                <div className='flex-row align-items-center'>
                  <Icon fill={'white'} name={'add-business'} />
                  <h1 className='text-semibold mb-0 ms-2'>
                    Your company is not verified
                  </h1>
                </div>
                <div>
                  We cannot process any mail for your account until you have
                  verified your identity and proof of address
                </div>
              </div>
              {!!feature_kyc.enabled && (
                <>
                  {kyc?.kyc_link ? (
                    <a
                      href={kyc?.kyc_link}
                      target='_blank'
                      className='btn px-10 btn-outline-light'
                      rel='noreferrer'
                    >
                      <span>Verify</span>
                    </a>
                  ) : (
                    <Link
                      href={'/business/owners'}
                      className='btn px-10 btn-outline-light'
                      rel='noreferrer'
                    >
                      Verify
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Nav
