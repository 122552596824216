import { useDispatch, useSelector } from 'react-redux'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { sdk } from 'common/sdk'
import { Res } from 'common/types/responses'
import { StoreStateType } from 'common/store'
import { useGetGroupedOffersQuery } from './useGroupedOffer'

type RedemptionUrlsMap = Record<string, string>

type InitialStateType = Res['getOfferRedemptionUrl'] | null

const initialState = null as InitialStateType

export const offerRedemptionUrlsSlice = createSlice({
  initialState,
  name: 'offerRedemptionUrls',
  reducers: {
    setOfferRedemptionUrls(state, action: PayloadAction<RedemptionUrlsMap>) {
      return action.payload
    },
  },
})

export const offerRedemptionUrlsActions = offerRedemptionUrlsSlice.actions
export const useGetOfferRedemptionUrlActions = () => {
  const dispatch = useDispatch()
  const setOfferRedemptionUrls = useCallback(
    (urls: RedemptionUrlsMap) => {
      return dispatch(offerRedemptionUrlsActions.setOfferRedemptionUrls(urls))
    },
    [dispatch],
  )
  return { setOfferRedemptionUrls }
}

const selectOfferRedemptionUrls = (state: StoreStateType) =>
  state.offerRedemptionUrls

/**
 * Hook to get redemption URLs for partner offers
 * @param partners Array of partner names to filter offers by
 * @returns An object with loading state, error, and redemption URLs mapped by brand name
 */
export const useGetOfferRedemptionUrl = (
  partners: string[] = ['Tide', 'Xero'],
) => {
  const { setOfferRedemptionUrls } = useGetOfferRedemptionUrlActions()
  const offerRedemptionUrls = useSelector(selectOfferRedemptionUrls)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const { data: groupedOffersData, isLoading: isGroupedOffersLoading } =
    useGetGroupedOffersQuery({})

  const allOffers = useMemo(() => {
    if (!groupedOffersData?.groups) return []
    return groupedOffersData.groups.flatMap(
      (group) => group.offers?.results || [],
    )
  }, [groupedOffersData])

  useEffect(() => {
    if (!allOffers.length) return
    const fetchRedemptionUrls = async () => {
      setLoading(true)
      try {
        const filteredOffers = allOffers.filter((offer) =>
          partners.includes(offer.brand.name),
        )
        if (filteredOffers.length === 0) {
          setOfferRedemptionUrls({})
          setLoading(false)
          return
        }

        const urlsMap: RedemptionUrlsMap = {}
        await Promise.all(
          filteredOffers.map(async (offer) => {
            const res = await sdk.createOfferRedemption({ id: offer.id })
            const brandKey = offer.brand.name.toLowerCase()
            urlsMap[brandKey] = res.url
          }),
        )
        setOfferRedemptionUrls(urlsMap)
      } catch (err) {
        setError(err instanceof Error ? err : new Error('An error occurred'))
        console.error('Error fetching offer redemption URLs:', err)
      } finally {
        setLoading(false)
      }
    }

    fetchRedemptionUrls()
  }, [allOffers, partners.join(','), setOfferRedemptionUrls])

  return {
    error,
    loading: loading || isGroupedOffersLoading,
    offerRedemptionUrls,
  }
}
