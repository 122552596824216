import { Req } from './types/requests'
import { Res } from './types/responses'
import _data from './_data'

export const sdk = {
  createOfferRedemption: (params: Req['createOfferRedemption']) => {
    return _data
      .get(`offers/${params.id}/redeem`, params)
      .then((res) => res as Res['offerRedemption'])
    // return Promise.resolve(responses.offerRedemption)
  },

  getOffer: (params: Req['getOffer']) => {
    return _data.get(`offers/${params.id}/`, params).then((res) => {
      return res as Res['offer']
    })
    // return Promise.resolve(responses.offer)
  },
  getOffers: (params: Req['getOffers']) => {
    return _data.get('offers/', params).then((res) => {
      return res as Res['offers']
    })
    // const { tags } = params as { tags: string[] }
    // const response = responses.offers.results.filter((offer) =>
    //   offer.tags.includes(tags[0]),
    // )
    // return Promise.resolve(responses.offers)
  },
  getTags: (params: Req['getTags']) => {
    return _data.get('tags/', params).then((res) => {
      return res as Res['tags']
    })
    // return Promise.resolve(responses.tags)
  },
  login: (params: Req['login']) => {
    // return _data.post('login/', params).then((res) => {
    //   return res
    // })
    return Promise.resolve()
  },
}
